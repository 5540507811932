<template>
  <a-modal v-model:visible="isShow"
           :bodyStyle="{ padding: 0, fontSize: '16px' }"
           :closable="false"
           :destroyOnClose="true"
           :footer="null"
           :width="380"
           class="team-change-modal">
    <div class="modal-header bg-gray">
      <h2 style="margin-top:-0.2rem;">
        팀 선택
        <a-tooltip :color="'#FFF'" overlayClassName="signer-tooltip" placement="right">
          <QuestionCircleFilled class="btn-question big"/>
          <template #title>
            <p class="content">
              상위팀 선택 시, 하위팀 정보도 함께 확인할 수 있습니다.
            </p>
          </template>
        </a-tooltip>
      </h2>
      <span class="modal-close" role="button" @click="isShow=false">닫기</span>
    </div>
    <a-spin :spinning="isLoading">
      <div class="modal-content team-select">
        <div class="modal-body">
          <h3>팀 이름</h3>
          <TeamList
            v-model:selectedTeam="selectedTeam"
            :listType="TeamListType.AssignObject"
            style="max-height: 400px; overflow: auto; border: solid 1px #d9d9d9; border-radius: 2px"
            @onSelectedTeam="onSelectedTeam"/>
          <div class="btn-row">
            <a-button class="btn" @click="isShow=false">취소</a-button>
            <a-button class="btn" type="primary" @click="confirm">완료</a-button>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import TeamList, { TeamListType } from 'components/common/teams/TeamList.vue'
import { useStore } from 'vuex'
import { QuestionCircleFilled } from '@ant-design/icons-vue'

export default {
  components: {
    TeamList,
    QuestionCircleFilled
  },
  props: {
    globalChange: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      isShow: false,
      selectedTeam: store.state.organization.selectedTeam,
      isLoading: false
    })
    const modal = ref(null)
    const show = (team = null) => {
      if (team) state.selectedTeam = team
      state.isShow = true
    }
    const hide = () => {
      state.isShow = false
    }
    const onSelectedTeam = team => {
      state.selectedTeam = team
    }
    const confirm = () => {
      emit('setTeam', state.selectedTeam)
      if (props.globalChange) {
        store.dispatch('setFolder', {
          folderId: '',
          name: '전체 폴더'
        })
      }
      hide()
    }

    return {
      show,
      hide,
      modal,
      onSelectedTeam,
      confirm,
      ...toRefs(state),
      TeamListType
    }
  }
}
</script>

<style lang="less" scoped>
.team-change-modal {
  .modal-header {
    padding: 2rem;
    border: none;
  }

  .modal-close {
    margin: 24px;
  }

  .modal-content {
    border: none;
  }

  .btn-row {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
  }

  .btn {
    width: 48%;
  }

  .teams-ul {
    height: 300px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none
    }
  }
}
</style>

<style lang="less">
.team-change-modal {
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    padding: 1em;
    font-weight: 400;
  }
}
</style>
