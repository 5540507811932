import store from '@/store'

/*
 * permissionScope
 * 0 : 조직 전체
 * 1 : 내팀과 하위팀 전체
 * 2 : 내팀
 * 3 : 하위팀
 * 4 : 본인
 */

/*
 * item 구성요소
 * { action, organizationId, teamId, userId }
 * organizationId, teamId값이 없을 경우, organizationId와 teamId를 비교하지 않고 오직 permission 존재여부만 확인
 */
const updatePermission = (el, item) => {
  // 조직계정이 아닐 경우 통과
  if (!store.state.organization.organization) {
    return true
  }

  if (!item) return true

  const permissionList = store.state.organization.permissionList
  const permission = permissionList.find(perm => perm.permissionName === item.action)

  // 권한이 없으면 요소 지우기
  let hasPermission = true
  if (permission === null || permission === undefined) {
    hasPermission = false
  } else if (item.organizationId && item.teamId) {
    if (permission.permissionScope === 0) {
      if (item.organizationId !== store.state.organization.organization.organizationId) {
        hasPermission = false
      }
    } else if (permission.permissionScope === 1) {
      const possibleTeamList = store.state.organization.childTeamList
      if (!possibleTeamList.find(team => team.teamId === item.teamId)) {
        hasPermission = false
      }
    } else if (permission.permissionScope === 2) {
      if (store.state.organization.myTeam.teamId !== item.teamId) {
        hasPermission = false
      }
    } else if (permission.permissionScope === 3) {
      const possibleTeamList = store.state.organization.childTeamList.filter(team => team.teamId !== store.state.organization.myTeam.teamId)
      if (!possibleTeamList.find(team => team.teamId === item.teamId)) {
        hasPermission = false
      }
    } else if (permission.permissionScope === 4) {
      if (!item.userId || item.userId !== store.state.user.userInfo.userId) {
        hasPermission = false
      }
    }
  }

  if (!hasPermission && el.parentNode) {
    el.parentNode.removeChild(el)
  }
}

const setPermissionsV2 = (app) => {
  app.directive('checkPermission', {
    mounted (el, binding) {
      updatePermission(el, binding.value)
    },
    updated (el, binding) {
      updatePermission(el, binding.value)
    }
  })
}

export default setPermissionsV2
