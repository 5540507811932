import store from '@/store'

const defaultMenus = [
  {
    title: '템플릿',
    icon: 'ico-template',
    name: 'Template'
  },
  {
    title: '대량전송',
    icon: 'ico-send',
    name: 'BulkTransmit'
  },
  {
    title: '결제 관리',
    icon: 'ico-payment',
    name: 'Payments'
  },
  // {
  //   title: '문서편집기',
  //   icon: 'ico-edit',
  //   name: 'EditingDocuments',
  //   permissions: ['admin', 'subAdmin', 'normalUser', 'organizationUser', 'teamUser']
  // },
  {
    title: '사인/도장',
    icon: 'ico-seal',
    name: 'SignStamp'
  },
  {
    title: '포인트 관리',
    icon: 'ico-point',
    name: 'Point'
  }
]

export const developerMenus = [
  {
    title: '소개',
    name: 'Dev-Index'
  },
  {
    title: '응답 코드',
    name: 'Dev-ResponseCode'
  },
  {
    title: '인증 & 인가',
    name: 'Dev-Auth'
  },
  {
    title: '서명 요청',
    name: 'Dev-SignRequest'
  },
  {
    title: '웹훅',
    name: 'Dev-Webhook'
  },
  {
    title: '임베딩 페이지',
    name: 'Dev-Embedding'
  },
  {
    title: 'OAUTH',
    name: 'Dev-Oauth'
  }
]

export const createMenu = () => {
  const organization = store.state.organization.organization

  if (organization) { // orginaztion user
    return [
      {
        title: '조직문서',
        icon: 'ico-papers',
        name: 'OrgIndex'

      },
      ...defaultMenus,
      {
        title: '조직 관리',
        icon: 'ico-subaccount',
        name: 'Subaccount'
      }
    ]
  } else {
    return [
      {
        title: '내문서',
        icon: 'ico-papers',
        name: 'Index'
      },
      ...defaultMenus
    ]
  }
}

export default createMenu
