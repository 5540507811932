import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setupAntd } from 'core/antd'
import { setupI18n } from 'core/i18n'
import '@/mock'
import 'assets/styles/main.less'
import setupDirectives from 'core/directives'
import VueGtag from 'vue-gtag-next'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { clearUserInfo } from 'utils/helper'

const app = createApp(App)
setupAntd(app)
setupI18n(app)
setupDirectives(app)

window.addEventListener('storage', function (e) {
  if (e.key === 'UCS_TOKEN' && e.newValue === '""') {
    clearUserInfo()
    router.push({ name: 'Login' })
  }
})

app
  .use(router)
  .use(store)
  .use(VueGtag, {
    property: {
      id: 'G-0SCTN9S7SF'
    }
  })
  .mount('#app')

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
