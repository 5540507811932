import request from 'utils/request'

export const getSamples = () => {
  return request.get('/documents/sample')
}

export const getSampleTemplates = () => {
  return request.get('/documents/sample-template')
}

export const uploadFile = requestData => {
  return request({
    url: '/documents/upload',
    method: 'post',
    timeout: 0,
    data: requestData
  })
}

export const createDocument = requestData => {
  return request({
    url: '/documents',
    method: 'post',
    data: requestData
  })
}

export const getDocFile = documentId => {
  return request({
    url: `/documents/${documentId}/file`,
    method: 'get'
  })
}

export const getDocDetail = documentId => {
  return request.get(`/documents/${documentId}`)
}

export const getCurrentField = (documentId, participantId) => {
  return request.get(`/documents/${documentId}/participants/${participantId}/fields`)
}

export const uploadSignature = (postData) => {
  return request.post('/signatures', postData)
}

export const signatureFonts = (name) => {
  return request.get(`signatures/fonts?name=${name}`)
}

export const signatureStamp = (name, ownerType = 'personal', language = 'KR') => {
  return request.get(`signatures/stamps/${ownerType}?name=${name}&language=${language}`)
}

export const signDocument = ({
  documentId,
  participantId,
  postData
}) => {
  return request.post(`documents/${documentId}/participants/${participantId}/signing`, postData)
}

export const participantAuth = (documentId, participantId) => {
  return request.get(`/documents/${documentId}/participants/${participantId}/auths`)
}

export const getSignaturesUsed = (params) => {
  return request.get('/signatures', { params })
}

export const getMessage = (documentId, participantId) => {
  return request.get(`/documents/${documentId}/participants/${participantId}/message`)
}

export const getAuthInfo = (documentId, participantId) => {
  return request.get(`/documents/${documentId}/participants/${participantId}/auths`)
}

export const authValidate = (documentId, participantId, authId, authType, authValue) => {
  return request.post(`/documents/${documentId}/participants/${participantId}/auths/${authId}/attempt`, {
    authType,
    authValue
  })
}

export const getAttachments = (documentId, assigned = false) => {
  return request.get(`/documents/${documentId}/attachments`, { params: { assigned } })
}

export const uploadAttachemnt = (documentId, fileName, fileBase64, ext) => {
  return request({
    timeout: 0,
    url: `/documents/${documentId}/attachments`,
    method: 'POST',
    data: {
      fileName: fileName,
      base64: fileBase64,
      ext: ext
    }
  })
}

export const updateAttachment = (documentId, attachmentId, fileInfo) => {
  return request.post(`/documents/${documentId}/attachments/${attachmentId}/file`, fileInfo)
}

export const deleteAttachment = (documentId, participantId, attachmentId) => {
  return request.delete(`/documents/${documentId}/participants/${participantId}/attachments/${attachmentId}/file`)
}

export const getAuditTrail = documentId => {
  return request.get(`/documents/${documentId}/audit-trail`)
}

export const getAttachmentFile = (documentId, attachmentId) => {
  return request.get(`/documents/${documentId}/attachments/${attachmentId}`)
}

export const authPhone = (documentId, participantId, authId, {
  name,
  phoneNumber
}) => {
  return request.post(`/documents/${documentId}/participants/${participantId}/auths/${authId}/verity/request`, {
    authType: 'mobile_identification',
    name,
    phoneNumber
  })
}

export const getPhoneAuthState = (documentId, participantId, authId) => {
  return request.get(`/documents/${documentId}/participants/${participantId}/auths/${authId}/verity/state`)
}

export const authOtp = (documentId, participantId, authId, {
  name,
  phoneNumber
}) => {
  return request.post(`/documents/${documentId}/participants/${participantId}/auths/${authId}/verity/request/otp`, {
    authType: 'mobile_otp',
    name,
    phoneNumber
  })
}

export const resendOtpLink = (documentId, participantId, authId) => {
  return request.put(`/documents/${documentId}/participants/${participantId}/auths/${authId}/verity/request/otp/resend`)
}

export const closeOtpAuth = (documentId, participantId, authId) => {
  return request.put(`/documents/${documentId}/participants/${participantId}/auths/${authId}/otp/close`)
}

export const verifyOtpCode = (documentId, participantId, authId, otpCode) => {
  return request.post(`/documents/${documentId}/participants/${participantId}/auths/${authId}/verity/otp`, { otpCode })
}
