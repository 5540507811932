<template>
  <div class="ucs-default-wrapper">
    <Sider ref="sider" :device="device" @createDoc="onCreateDoc"/>
    <section class="container">
      <TopBanner ref="topBanner"/>
      <div class="a-header">
        <h2>{{ title }}</h2>
        <TitleTooltip/>
      </div>
      <section class="a-cont">
        <router-view></router-view>
      </section>
    </section>
  </div>
  <div class="modals">
    <DefaultModals ref="defaultModals"/>
    <UploadModal ref="uploadModal"/>
    <LackPointModal ref="lackPointModal"/>
    <SimpleModal
      ref="immediateSignModal"
      :cancelText="processType === DocumentProcessTypes.FACE_TO_FACE ? '나중에 하기' : '취소'"
      :okText=" processType === DocumentProcessTypes.FACE_TO_FACE ? '지금 시작하기' : '서명 진행'"
      @confirm="toSignImmdiately"
    >
      <template #title>
        <h3 v-if="processType === DocumentProcessTypes.FACE_TO_FACE">대면서명 시작하기</h3>
        <h3 v-else>서명 시작하기</h3>
      </template>
      <template #textContent>
        <p v-if="processType === DocumentProcessTypes.FACE_TO_FACE">
          대면서명 요청이 완료되었습니다.<br/>
          지금 바로 대면서명을 진행하시겠습니까?
        </p>
        <p v-else>
          서명 요청이 완료되었습니다. <br/>
          계약의 첫 번째 서명 참여자입니다. <br/>
          바로 서명을 진행하시겠습니까?
        </p>
      </template>
    </SimpleModal>
    <RequestKakaoConfirmModal ref="requestKakaoConfirmModal"/>
  </div>
  <FaceToFaceStartModal ref="faceToFaceStartModal" @confirm="goToFaceToFaceSigning"/>
</template>

<script>
import Sider from 'components/default/sider/Sider.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, createVNode, nextTick, onBeforeMount, onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { Modal } from 'ant-design-vue'
import TopBanner from 'components/default/header/TopBanner'
import UploadModal from 'components/signing/UploadModal'
import LackPointModal from 'components/common/LackPointModal'
import SimpleModal from 'components/common/SimpleModal'
import TitleTooltip from 'components/common/TitleTooltip'
import RequestKakaoConfirmModal from 'components/common/RequestKakaoConfirmModal'
import DefaultModals from 'components/modal/DefaultModals'
import { autoCharge, getPointPaymentMethod, getSubscriptionInfo } from '@/api/point'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import { DocumentProcessTypes } from 'utils/commonValue'
import FaceToFaceStartModal from 'components/common/FaceToFaceStartModal'
import { getDocDetail } from 'api/sign'
import { saveStartFaceToFaceHistory } from 'api/documents'

export default {
  components: {
    Sider,
    TopBanner,
    UploadModal,
    LackPointModal,
    SimpleModal,
    TitleTooltip,
    DefaultModals,
    RequestKakaoConfirmModal,
    FaceToFaceStartModal
  },
  setup () {
    const uploadModal = ref(null)
    const store = useStore()
    const device = ref(store.state.app.device)
    const topBanner = ref(null)
    const lackPointModal = ref(null)
    const immediateSignModal = ref(null)
    const requestKakaoConfirmModal = ref(null)
    const defaultModals = ref(null)
    const processType = ref(DocumentProcessTypes.PROCEDURE)
    const faceToFaceStartModal = ref(null)
    const router = useRouter()
    const handleLayouts = () => {
      const width = document.body.getBoundingClientRect().width
      const isMobile = width - 1 < 768
      const deviceOne = isMobile ? 'mobile' : 'desktop'
      store.dispatch('setDevice', deviceOne)
      device.value = deviceOne
    }
    onBeforeMount(() => {
      store.dispatch('userInfo')
    })
    onMounted(() => {
      defaultModals.value.showDefaultModal()
      topBanner.value.showBanner()
      handleLayouts()
      window.addEventListener('resize', handleLayouts)
      setPoint()
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleLayouts)
    })

    const route = useRoute()
    const sider = ref(null)

    const title = computed(() => {
      return route.meta.title
    })

    const currentPageName = computed(() => {
      return route.name
    })

    watchEffect(() => {
      if (store.state.notice.isShowLackPointModal) {
        lackPointModal.value.show()
        store.state.notice.isShowLackPointModal = false
      }
    })
    const classObj = device.value === 'mobile' ? ref('app-mobile') : ref('app-collapse')
    const onCreateDoc = () => {
      if (store.state.app.point !== 0) {
        uploadModal.value.show()
      } else {
        store.state.notice.isShowLackPointModal = true
      }
    }

    const setPoint = () => {
      store.dispatch('setPoint').then(async () => {
        if (store.state.app.point === 0) {
          const paymentMethod = await getPointPaymentMethod()
          const subscription = await getSubscriptionInfo()
          if (paymentMethod.result && subscription.result && paymentMethod.result.active && subscription.result.active) {
            autoCharge().then(() => {
              store.dispatch('setPoint')
            }).catch((err) => {
              store.dispatch('userInfo')
              Modal.confirm({
                title: '포인트 자동충전에 실패했습니다. 등록하신 카드를 다시 한 번 확인해 주세요.',
                icon: createVNode(CloseCircleOutlined, { style: 'color:red;' }),
                content: `사유 : ${err.msg}`,
                okText: '충전설정으로',
                cancelText: '취소',
                onOk () {
                  router.push({
                    name: 'Point',
                    hash: '#2'
                  })
                }
              })
            })
          }
        }
      })
    }

    let immediateOpenDocId = ''
    watchEffect(() => {
      if (store.state.app.immediateSignPop) {
        nextTick(() => {
          if (store.state.app.immediateSignPop === 1) {
            Modal.success({ content: '서명 요청이 완료되었습니다.' })
          } else if (store.state.app.immediateSignPop === 2) {
            Modal.success({ content: '서명 및 결제 요청이 완료되었습니다.' })
          } else if (store.state.app.immediateSignPop === 3) {
            Modal.success({ content: '서명 예약 신청이 완료되었습니다.' })
          } else if (store.state.app.immediateSignPop === 4) {
            processType.value = DocumentProcessTypes.PROCEDURE
            immediateSignModal.value.show()
            immediateOpenDocId = store.state.app.immediateSignDocumentId
          } else if (store.state.app.immediateSignPop === 5) {
            processType.value = DocumentProcessTypes.FACE_TO_FACE
            immediateSignModal.value.show()
            immediateOpenDocId = store.state.app.immediateSignDocumentId
          }
          store.state.app.immediateSignPop = false
        })
      }
    })
    watchEffect(() => {
      if (store.state.user.userInfo.type !== 'SUB_ACCOUNT' && store.state.app.firstRequestPaymentPop) {
        nextTick(() => {
          requestKakaoConfirmModal.value.show()
          store.state.app.firstRequestPaymentPop = false
        })
      }
    })

    const toSignImmdiately = () => {
      if (processType.value === DocumentProcessTypes.FACE_TO_FACE) {
        immediateSignModal.value.hide()
        getDocDetail(immediateOpenDocId).then(res => {
          faceToFaceStartModal.value.show(res.result)
        })
      } else {
        router.push({
          name: 'Authentication',
          params: { documentId: immediateOpenDocId }
        })
      }
    }

    const goToFaceToFaceSigning = (document, participantId = null) => {
      saveStartFaceToFaceHistory(immediateOpenDocId)
      router.push({
        name: 'Authentication',
        query: { pi: participantId },
        params: { documentId: document.documentId }
      })
    }

    // eslint-disable-next-line no-undef
    ChannelIO('showChannelButton')

    return {
      handleLayouts,
      classObj,
      device,
      title,
      currentPageName,
      uploadModal,
      onCreateDoc,
      sider,
      topBanner,
      lackPointModal,
      immediateSignModal,
      faceToFaceStartModal,
      requestKakaoConfirmModal,
      toSignImmdiately,
      defaultModals,
      DocumentProcessTypes,
      processType,
      setPoint,
      goToFaceToFaceSigning
    }
  }
}
</script>

<style lang="less">
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  margin-right: 6px;
}

.line {
  background: #e4e4e4;
  height: 1px;
}

.error {
  color: #d00000;
}

.app-top {
  margin-bottom: 35px;
}

.app-wrapper {
  .app-right {
    background: #fff;
  }

  .app-container {
    padding: 12px 12px 0;
  }

  .app-footer {
    border-top: 1px solid #eee;
    padding: 6px;
    position: fixed;
    background: #f8f8f8;
    bottom: 0;
    color: #757575;
    font-size: 12px;
    left: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
  }
}

.ant-btn-link.a-btn {
  color: #bdbdbd;
  transition: color 0.2s;
  @media (hover: hover) {
    &:hover {
      color: inherit;
    }
  }
}

.underline {
  text-decoration: underline;
}

.box-5 {
  padding: 30px 30px 18px;
}

.loop(@counter) when (@counter > 0) {
  .p-@{counter} {
    padding: (1px * @counter);
  }
  .pt-@{counter} {
    padding-top: (1px * @counter);
  }
  .pr-@{counter} {
    padding-right: (1px * @counter);
  }
  .pb-@{counter} {
    padding-bottom: (1px * @counter);
  }
  .pl-@{counter} {
    padding-left: (1px * @counter);
  }
  .m-@{counter} {
    margin: (1px * @counter);
  }
  .mt-@{counter} {
    margin-top: (1px * @counter);
  }
  .mr-@{counter} {
    margin-right: (1px * @counter);
  }
  .mb-@{counter} {
    margin-bottom: (1px * @counter);
  }
  .ml-@{counter} {
    margin-left: (1px * @counter);
  }
  .fz-@{counter} {
    font-size: (1px * @counter);
  }
  .loop((@counter - 1)); // 递归调用自身
}

.loop(50);
.ucs-default-wrapper {
  display: flex;
  min-height: 100vh;
}

</style>
