<template>
  <header>
    <h1>
      <router-link :to="{ name: 'Home' }"
      ><span class="logo logo-sm white">유캔싸인</span>
        <small>Developers</small>
        <!-- <em>관리자</em> -->
      </router-link>
    </h1>
    <div :class="menuClass" class="menu" role="button" @click="isMenuExpand = !isMenuExpand">
      <span class="line"></span>
    </div>
  </header>
  <aside :class="asideClass" class="aside">
    <div class="side-wrap">
      <nav>
        <div class="menu-list">
          <ul class="slide-menu">
            <li
              v-for="menu in menus"
              :key="menu.title"
              :class="{ depth: menu.sub }"
            >
              <router-link
                :class="{ active: currentSelected === menu.name, hasBackIcon: menu.backIcon }"
                :style="menu.sytle || {}"
                :to="{name: menu.name }"
                @click="clickMenu(menu)"
              >
                <span :class="{ new: updatedTitles.includes(menu.name) }"><i v-if="menu.icon" :class="menu.icon" class="ico"></i>{{ menu.title }}</span>
              </router-link>
              <ul v-if="menu.sub" class="sub">
                <li
                  v-for="subMenu in menu.sub"
                  :key="subMenu.title"
                  @click="onClickSubMenu(menu, subMenu)"
                >
                  <router-link :class="{ active: currentSelected === menu.name && subMenuSelected === subMenu.name }" :to="{name: subMenu.name }">
                    {{ subMenu.title }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <div class="update-btn">
        <a-button class="btn btn-dark-blue btn-md btn-rounded" type="button" @click="goToApiReference">
          <span>API 레퍼런스</span>
        </a-button>
      </div>
      <div class="update-btn">
        <router-link :to="{ name: 'Dev-UpdateLog' }">
          <a-button class="btn btn-dark-blue btn-md btn-rounded" type="button">
            <span>업데이트 로그</span>
          </a-button>
        </router-link>
      </div>
      <div class="update-btn">
        <router-link :to="{ name: 'Home' }">
          <a-button class="btn btn-dark-blue btn-md btn-rounded" type="button">
            관리자 페이지로 이동
          </a-button>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
import LogoImg from 'assets/images/logo.svg'
import { computed, onMounted, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { developerMenus } from 'utils/menus'

export default {
  props: {
    device: String
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      menus: developerMenus,
      collapsed: store.state.app.collapsed,
      isMenuExpand: false,
      currentSelected: route.name,
      subMenuSelected: route.name,
      currentTitle: '',
      updatedTitles: []
    })

    const menuClass = computed(() => {
      return { close: props.device === 'mobile' && state.isMenuExpand }
    })

    const asideClass = computed(() => {
      return { 'aside-hide': props.device === 'mobile' && !state.isMenuExpand }
    })

    onMounted(() => {
      setMenu(route)
    })

    watch(() => route.name, () => {
      setMenu(route)
      state.isMenuExpand = false
    })

    const clickMenu = menu => {
      state.currentSelected = menu.name
      state.subMenuSelected = ''
      state.currentTitle = menu.title
      window.scrollTo(0, 0)
    }

    const onClickSubMenu = (menu, subMenu) => {
      state.subMenuSelected = subMenu.name
      state.currentTitle = subMenu.title
      window.scrollTo(0, 0)
    }

    const setMenu = route => {
      state.currentSelected = route.name
      if (route.meta.parentMenuName) {
        state.subMenuSelected = route.name
        state.currentSelected = route.meta.parentMenuName
      }
    }

    const goToApiReference = () => {
      window.open('https://documenter.getpostman.com/view/20616084/2s7YfHhcGY', '_blank')
    }

    return {
      router,
      menuClass,
      asideClass,
      LogoImg,
      ...toRefs(state),
      clickMenu,
      onClickSubMenu,
      goToApiReference
    }
  }
}
</script>

<style lang="less" scoped>
.aside {
  transition: all 0.2s;
  opacity: 1;
  background: #16191e;
}

.side-wrap {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .update-btn {
    &:first-of-type {
      margin-top: 2rem;
      border-top: 1px solid #202832;
      padding-top: 4rem;

      .btn {
        background: #27d0b0;
        text-shadow: 1px 1px 10px #1cbe9f;
      }
    }

    &:last-of-type {
      .btn {
        background: #1890ff
      }
    }

    .btn {
      background: #313f67;
      color: #fff;
      margin-bottom: 1rem;

      &:hover, &:focus {
        border: 1px solid #3c4c7c
      }
    }
  }
}

.aside-hide {
  visibility: hidden;
  opacity: 0;
}

// old
.app-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000;
  opacity: 0.5;
}

.admin_logo {
  height: 32px;
  margin: 16px;
  text-align: center;
}

.app-sider {
  background: hsl(214, 23%, 19%);
  width: 100%;
  flex: 0 0 100%;

  &.ant-layout-sider-below {
    position: fixed;
    z-index: 9;
  }

  .sider-top {
    position: relative;
    z-index: 1;

    .sign-btn {
      padding: 12px;
      position: relative;
      text-align: center;

      button {
        font-size: 14px;
        height: 36px;
        padding: 5px 40px 5px 40px;
      }
    }
  }

  .sider-menu {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 300px);
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    .ant-menu-item {
      padding-left: 0px;
      text-align: left;
    }
  }

  .sider-bottom {
    .sign-btn {
      padding: 12px;
      position: relative;
      text-align: center;
    }
  }
}

.slide-menu {
  border: 0;
  border-top: 1px solid #202832;
  margin-top: 0.5rem;

  li {
    a {
      &.active {
        &:before {
          background: #1e2228;
        }
      }
    }
  }
}

h1 {
  a {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    small {
      font-size: 1.1rem;
      color: #fff;
      margin-left: 0.5rem;
      font-family: 'Arial';
      letter-spacing: 1px;
    }
  }
}

.menu-list a {
  font-size: 1.5rem
}
</style>
