<template>
  <div class="tab-content">
    <a-spin :spinning="loading" style="height:240px">
      <ul class="chk-list">
        <li v-for="(sample, index) in sampleTemplates" :key="index">
          <label :for="`sampleTemplate_${index}`" class="input-chk">
            <input :id="`sampleTemplate_${index}`" :value="index" name="sampleTemplate" type="radio" @change="handleChange(sample)"/>
            <div class="chk-box">
              <span class="checkbox"><i class="ico ico-docu"></i></span>
              <span class="txt">{{ sample.name }}</span>
            </div>
          </label>
        </li>
      </ul>
    </a-spin>
    <div v-show="sampleTemplates.length" class="btn-center">
      <a-button :disabled="!currentSample" class="btn" style="width: 240px;" type="primary" @click="goNext">업로드</a-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue'
import { useSampleTemplate } from 'use/sign/useSamples'

export default {
  setup (props, { emit }) {
    const state = reactive({
      currentSample: null
    })
    const {
      loading,
      sampleTemplates,
      downloadLink
    } = useSampleTemplate()
    const handleChange = (sample) => {
      state.currentSample = sample
    }
    const goNext = () => {
      if (state.currentSample != null) {
        const currentPdf = {
          name: state.currentSample.name,
          documentId: state.currentSample.documentId
        }
        emit('finishUpload', currentPdf, '샘플')
      }
    }

    watchEffect(() => {
      emit('downloadLink', downloadLink)
    })

    return {
      loading,
      sampleTemplates,
      handleChange,
      goNext,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.chk-list {
  overflow: auto;
  max-height: 240px;
}

@media only screen and (max-width: 768px) {
  .btn-center {
    margin-top: 15px;
  }
}
</style>
