import request from 'utils/request'

/**
 * 获取原始文档列表
 * @param {name: "", timestamp: 0, page: 1, countPerPage: 10} parma obj
 * @returns
 */
export const list = (params) => {
  return request.get('/templates', { params })
}

/**
 * 删除原始文档
 * @param  {templateIds: [0]} parma obj
 * @returns
 */
export const deleteTemplate = (parma) => {
  return request.post('/templates/delete', parma, { config: { isShowMessage: false } })
}

export const createTemplate = templateData => {
  return request.post('/templates', templateData)
}

export const updateTemplate = templateData => {
  return request.put(`/templates/${templateData.documentId}`, templateData)
}

export const getTemplateDetail = templateId => {
  return request.get(`/templates/${templateId}`)
}

export const getTestDocument = () => {
  return request.get('/documents/test')
}

export const copyTemplates = (templateIds) => {
  return request.post('/templates/copy', templateIds)
}

export const renameTemplate = (templateId, data) => {
  return request.put(`/templates/${templateId}/name`, data)
}
