import createStorage from 'utils/storage'
import { loginType, refreshToken, savedMainId, savedSubId, savedUserId, userInfo, userToken } from 'config/config'
import { getUserInfo as userInfoApi, login as loginApi, logout as logoutApi, sublogin as subloginApi } from 'api/user'
import md5 from 'md5'
import { clearLinkSessions } from 'utils/helper'

const storage = createStorage()
const user = {
  state: {
    token: storage.get(userToken),
    refresh: storage.get(refreshToken),
    isTokenRefreshing: false,
    userInfo: storage.get(userInfo),
    savedId: storage.get(savedUserId, ''),
    savedMainId: storage.get(savedMainId, ''),
    savedSubId: storage.get(savedSubId, ''),
    loginType: storage.get(loginType, 'main')
  },
  mutations: {
    setToken (state, token) {
      storage.set(userToken, token)
      state.token = token
    },
    setRefreshToken (state, refresh) {
      storage.set(refreshToken, refresh)
      state.refresh = refresh
    },
    setIsTokenRefreshing (state, value) {
      state.isTokenRefreshing = value
    },
    setUserInfo (state, value) {
      storage.set(userInfo, value)
      state.userInfo = value
    },
    setSavedId (state, value) {
      storage.set(savedUserId, value)
      state.savedId = value
    },
    setSavedSubId (state, value) {
      storage.set(savedMainId, value.id)
      state.savedMainId = value.id
      storage.set(savedSubId, value.subId)
      state.savedSubId = value.subId
    },
    setLoginType (state, value) {
      state.loginType = value
      storage.set(loginType, value)
    }
  },
  actions: {
    login ({
      commit,
      dispatch
    }, parameter) {
      const { password } = parameter
      if (password) {
        const newValue = md5(password)
        parameter.password = newValue
      }
      return loginApi(parameter.id, parameter.password)
        .then(res => {
          var id = ''
          if (parameter.saveId === true) {
            id = parameter.id
          }
          commit('setLoginType', 'main')
          commit('setSavedId', id)
          commit('setToken', res.result.accessToken)
          if (res.result.refreshToken) {
            commit('setRefreshToken', res.result.refreshToken)
          }
          // setPermissions()
          return userInfoApi()
        })
        .then(res => {
          const { result } = res
          commit('setUserInfo', result)
          dispatch('getOrganizationInfo')
        })
    },
    sublogin ({
      commit,
      dispatch
    }, parameter) {
      const { password } = parameter
      if (password) {
        const newValue = md5(password)
        parameter.password = newValue
      }
      return subloginApi(parameter.id, parameter.password)
        .then(res => {
          var id = ''
          if (parameter.saveId === true) {
            id = parameter.id
          }
          commit('setLoginType', 'sub')
          commit('setSavedId', id)
          commit('setToken', res.result.accessToken)
          if (res.result.refreshToken) {
            commit('setRefreshToken', res.result.refreshToken)
          }
          // setPermissions()
          return userInfoApi()
        })
        .then(res => {
          const { result } = res
          commit('setUserInfo', result)
          dispatch('getOrganizationInfo')
        })
    },
    logout ({
      commit,
      dispatch
    }) {
      const clear = () => {
        clearLinkSessions()
        dispatch('resetOrganizationInfo')
        commit('setToken', '')
        commit('setRefreshToken', '')
        commit('setUserInfo', '')
      }
      return new Promise((resolve, reject) => {
        logoutApi()
          .then(res => {
            resolve(res)
          }).catch(err => {
            if (err.code === 1024) {
            // clear()
              resolve({
                code: 0,
                msg: err.msg
              })
            } else {
              reject(err)
            }
          }).finally(() => {
            clear()
          })
      })
    },
    userInfo ({
      commit,
      dispatch
    }) {
      return userInfoApi().then(res => {
        const {
          result,
          code
        } = res
        if (code === 0) {
          commit('setUserInfo', result)
          dispatch('getOrganizationInfo')
        } else {
          commit('setUserInfo', {})
        }
        // setPermissions()
        return res
      })
    }
  }
}

export default user
