<template>
  <div>
    <a-table
      :bordered="false"
      :columns="columns"
      :customRow="customRow"
      :data-source="teams"
      :defaultExpandAllRows="true"
      :expandIcon="expandIcon"
      :expandedRowKeys="expandedRowKeys"
      :loading="loadingTeams"
      :pagination="false"
      :row-class-name="(record) => record.teamId === selectedTeam.teamId ? 'team-selected' : ''"
      :scroll="{ x: isMobile && needScroll ? 450 : false }"
      :showHeader="listType === TeamListType.Operation"
      class="sub-account-table"
      rowKey="teamId"
      @expand="expand"
    >
      <template #checked="{ text: teamId, record }">
        <a-radio
          v-if="listType !== TeamListType.Selector || record.depth < 3"
          :checked="teamId === selectedTeam.teamId"
          @click="onSelectedTeam(record)"
        />
      </template>
      <template #operation="{ record: team }">
        <span v-if="!team.defaultY">
          <a v-checkPermission="{action: 'EDIT_TEAM', organizationId: team.organizationId, teamId: team.teamId}" class="text-line text-blue modify-btn" @click="modifyTeam(team)">수정</a>
          <a v-checkPermission="{action: 'DELETE_TEAM', organizationId: team.organizationId, teamId: team.teamId}" class="text-line text-blue" @click="deleteTeam(team)">삭제</a>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { computed, createVNode, defineComponent, onMounted, reactive, toRefs, watch } from 'vue'
import { deleteTeam as deleteTeamApi, getAllTeamsApi } from 'api/organization'
import { Modal } from 'ant-design-vue'
import { DownOutlined, ExclamationCircleOutlined, UpOutlined } from '@ant-design/icons-vue'
import { contentPre } from 'utils/util'
import { useMediaQuery } from '@vueuse/core'
import { useStore } from 'vuex'

export const TeamListType = {
  Selector: 1,
  Operation: 2,
  Move: 3,
  AssignObject: 4
}

export default defineComponent({
  props: {
    selectedTeam: {
      type: Object,
      default: null
    },
    targetTeamId: {
      type: String,
      default: ''
    },
    listType: {
      type: Number,
      default: 1
    },
    onlyBelongedTeams: {
      type: Boolean,
      default: true
    },
    needScroll: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:selectedTeam', 'onSelectedTeam', 'teamUpdated', 'editTeam'],

  setup (props, {
    emit,
    expose
  }) {
    const store = useStore()
    const isMobile = useMediaQuery('(max-width: 768px)')

    const selectorColumns = [
      {
        key: 'name',
        width: '100%',
        customRender: ({ record }) => {
          return record.name + ` (${record.memberCount})`
        }
      },
      {
        dataIndex: 'teamId',
        key: 'checked',
        slots: {
          customRender: 'checked'
        }
      }
    ]
    const operationColumns = [
      {
        title: '팀 이름',
        dataIndex: 'name'
      },
      {
        title: '구성원',
        dataIndex: 'memberCount',
        width: '120px',
        align: 'center'
      },
      {
        title: '관리',
        key: 'operation',
        slots: {
          customRender: 'operation'
        },
        width: '120px',
        align: 'center'
      }
    ]
    const moveColumns = [
      {
        dataIndex: 'name',
        width: '100%'
      },
      {
        dataIndex: 'teamId',
        key: 'checked',
        slots: {
          customRender: 'checked'
        }
      }
    ]
    const columns = computed(() => {
      var val = []
      switch (props.listType) {
        case TeamListType.Selector:
        case TeamListType.AssignObject:
          val = selectorColumns
          break
        case TeamListType.Operation:
          val = operationColumns
          break
        case TeamListType.Move:
          val = moveColumns
          break
        default:
          break
      }
      return val
    })
    const customRow = (record, index) => {
      return {
        // class: {
        //   'custom-row-target': props.targetTeamId === record.teamId
        // },
        style: {
          // background: props.targetTeamId === record.teamId ? '#f5f5f5 !important' : 'none',
          color: props.targetTeamId === record.teamId ? '#1890ff' : '#262626'
          // 'font-family': '"Noto Sans KR", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif',
          // 'font-size': '13.216px',
          // 'font-variant-caps': 'normal',
          // 'font-variant-east-asian': 'normal',
          // 'font-variant-ligatures': 'normal',
          // 'font-variant-numeric': 'tabular-nums',
          // 'font-weight': '400'
        }
        // onMouseenter: (event) => {
        //   var ev = event || window.event
        //   ev.target.draggable = props.listType === TeamListType.Move && props.targetTeamId === record.teamId
        // },
        // onDragstart: (event) => {
        //   var ev = event || window.event
        //   ev.stopPropagation()
        // },
        // onDragover: (event) => {
        //   var ev = event || window.event
        //   ev.preventDefault()
        // },
        // onDrop: (event) => {
        //   var ev = event || window.event
        //   ev.stopPropagation()
        //   moveCurrentTeam(record)
        // }
      }
    }

    const teamListData = reactive({
      loadingTeams: false,
      teams: [],
      expandedRowKeys: [],
      rootTeam: null,
      selectedTeam: props.selectedTeam || '',
      selectedList: []
    })
    watch(() => props.selectedTeam, (now, pre) => {
      if (teamListData.selectedTeam !== now) teamListData.selectedTeam = now
    })
    const allTeamsId = teams => {
      const ids = []
      if (teams) {
        teams.forEach(element => {
          ids.push(element.teamId)
          if (element.children) {
            const subIds = allTeamsId(element.children)
            ids.push(...subIds)
          }
        })
      }
      return ids
    }
    const getAllTeams = async () => {
      teamListData.loadingTeams = true
      try {
        let res
        if (props.listType === TeamListType.Operation) {
          res = await getAllTeamsApi(true, null)
        } else {
          res = await getAllTeamsApi(true, store.state.organization.organization.teamId)
          if (store.state.organization.organization.roleId === '1002') {
            delete res.result.children
          }
        }
        teamListData.rootTeam = res.result || {}
        if (props.selectedTeam && !props.selectedTeam.teamId) {
          onSelectedTeam(teamListData.rootTeam)
        }
        teamListData.teams = res.result ? [res.result] : []
        teamListData.expandedRowKeys = allTeamsId(teamListData.teams)
        targetTeam = getTargetTeam(props.targetTeamId, teamListData.teams)
      } catch (error) {
        Modal.error({ content: (<pre>{error.msg}</pre>) })
      }
      teamListData.loadingTeams = false
    }
    const expand = (expanded, record) => {
      if (expanded) {
        teamListData.expandedRowKeys.push(record.teamId)
      } else {
        teamListData.expandedRowKeys = teamListData.expandedRowKeys.filter((value) => {
          return value !== record.teamId
        })
      }
    }

    const onSelectedTeam = team => {
      if (props.listType === TeamListType.Move) {
        moveCurrentTeam(team)
      } else {
        let realTeam = ''
        if (props.selectedTeam !== team) {
          realTeam = team
        } else {
          realTeam = teamListData.rootTeam
        }
        teamListData.selectedTeam = realTeam
        emit('onSelectedTeam', realTeam)
      }
    }

    watch(() => props.selectedTeam, (value, old) => {
      if ((!value || value === '') && teamListData.rootTeam) {
        onSelectedTeam(teamListData.rootTeam)
      }
    })

    onMounted(() => {
      getAllTeams()
    })

    const modifyTeam = team => {
      emit('editTeam', team)
    }

    const deleteTeam = team => {
      Modal.confirm({
        content: `"${team.name}"을 삭제하시겠습니까? 해당팀에 포함되어 있던 구성원들은 기본팀으로 전환됩니다.`,
        icon: createVNode(ExclamationCircleOutlined),
        onOk: async () => {
          try {
            await deleteTeamApi(team.teamId)
            teamChanged()
          } catch (error) {
            Modal.error({ content: contentPre(error) })
          }
        }
      })
    }

    let targetTeam = {}
    watch(() => props.targetTeamId, (now, pre) => {
      targetTeam = getTargetTeam(now, teamListData.teams)
    })
    const getTargetTeam = (teamId, teams) => {
      if (!teams) {
        return null
      }
      for (let index = 0; index < teams.length; index++) {
        const element = teams[index]
        if (element.teamId === teamId) {
          return element
        }
        const item = getTargetTeam(teamId, element.children)
        if (item) {
          return item
        }
      }
    }
    const totalDepth = team => {
      var depth = 1
      if (team.children) {
        var subDepth = 1
        team.children.forEach(element => {
          const currentDepth = totalDepth(element)
          if (currentDepth > subDepth) subDepth = currentDepth
        })
        depth += subDepth
      }
      return depth
    }
    const popTeam = (teamId, teams) => {
      if (!teams) {
        return null
      }
      for (let index = 0; index < teams.length; index++) {
        const element = teams[index]
        if (element.teamId === teamId) {
          teams.splice(index, 1)
          return element
        }
        const item = popTeam(teamId, element.children)
        if (item) {
          if (element.children.length === 0) element.children = null
          return item
        }
      }
    }
    const addSubteam = (teamId, teams, source) => {
      if (!teams) {
        return false
      }
      for (let index = 0; index < teams.length; index++) {
        const element = teams[index]
        if (element.teamId === teamId) {
          if (element.children) {
            element.children.push(source)
          } else {
            element.children = [source]
          }
          return true
        }
        const result = addSubteam(teamId, element.children, source)
        if (result) {
          return true
        }
      }
    }
    const moveCurrentTeam = to => {
      try {
        if (to.teamId === targetTeam.teamId) {
          return
        }
        const subIds = allTeamsId(targetTeam.children)
        if (subIds.includes(to.teamId)) {
          throw new Error('같은 소속의 하위팀으로 변경할 수 없습니다.')
        }

        const targetDepth = totalDepth(targetTeam)
        if (to.depth + targetDepth > 3) {
          throw new Error('팀 계층 구조는 최대 4단을 넘을 수 없습니다.')
        }

        teamListData.selectedTeam.teamId = to.teamId
        const newTeams = teamListData.teams
        const item = popTeam(props.targetTeamId, newTeams)
        if (item) {
          addSubteam(to.teamId, newTeams, item)
          teamListData.teams = newTeams
        }

        targetTeam.parentTeamId = to.teamId
      } catch (error) {
        Modal.error({ content: contentPre(error) })
      }
    }

    const teamChanged = () => {
      getAllTeams()
      emit('teamUpdated')
    }

    const refresh = () => {
      getAllTeams()
    }

    const expandIcon = (props) => {
      if (props.record.defaultY) {
        return (<span style="margin: 0px 0px 0px 0px;"></span>)
      }
      if (props.record.children) {
        return (<a style="color: #a0a0a0; margin: 0px 12px 0px 0px;" onClick={(e) =>
          props.onExpand(props.record, e)
        }>
          {props.expanded ? <DownOutlined/> : <UpOutlined/>}
        </a>)
      } else {
        return (<span style="margin: 0px 26px 0px 0px;"></span>)
      }
    }

    return {
      TeamListType,
      columns,
      customRow,
      ...toRefs(teamListData),
      expand,
      onSelectedTeam,
      modifyTeam,
      teamChanged,
      deleteTeam,
      refresh,
      expandIcon,
      isMobile
    }
  }
})
</script>

<style lang="less" scoped>
.modify-btn::after {
  content: "|";
  color: #f0f0f0;
  padding: 0 5px 0 5px;
}
</style>
