import createStorage from 'utils/storage'
import { getAllTeamsApi, getOrganizationInfo } from 'api/organization'
import { getTeamInfo } from 'api/folder'

const storage = createStorage()
const organization = {
  state: {
    organization: storage.get('organization'),
    allTeamList: storage.get('allTeamList'),
    childTeamList: storage.get('childTeamList'),
    myTeam: storage.get('myTeam'),
    permissionList: storage.get('permissionList'),
    selectedTeam: storage.get('selectedTeam') || null
  },
  mutations: {
    SET_ORGANIZATION (state, organization) {
      storage.set('organization', organization)
      state.organization = organization
    },
    SET_ALL_TEAM_LIST (state, allTeamList) {
      storage.set('allTeamList', allTeamList)
      state.allTeamList = allTeamList
    },
    SET_CHILD_TEAM_LIST (state, childTeamList) {
      storage.set('childTeamList', childTeamList)
      state.childTeamList = childTeamList
    },
    SET_MY_TEAM (state, myTeam) {
      storage.set('myTeam', myTeam)
      state.myTeam = myTeam
    },
    SET_PERMISSION_LIST (state, permissionList) {
      storage.set('permissionList', permissionList)
      state.permissionList = permissionList
    },
    SET_SELECTED_TEAM (state, selectedTeam) {
      storage.set('selectedTeam', selectedTeam)
      state.selectedTeam = selectedTeam
    }
  },
  actions: {
    resetOrganizationInfo ({ commit }) {
      commit('SET_ORGANIZATION', null)
      commit('SET_ALL_TEAM_LIST', null)
      commit('SET_CHILD_TEAM_LIST', null)
      commit('SET_MY_TEAM', null)
      commit('SET_PERMISSION_LIST', null)
      commit('SET_SELECTED_TEAM', null)
    },
    async getOrganizationInfo ({
      state,
      commit
    }) {
      const orgRes = await getOrganizationInfo()
      if (orgRes.code === 0 && orgRes.result) {
        const organization = orgRes.result
        commit('SET_ORGANIZATION', organization)
        commit('SET_PERMISSION_LIST', organization.permissions)

        const teamRes = await getTeamInfo(organization.teamId)
        if (teamRes.code === 0 && teamRes.result) {
          const team = teamRes.result
          commit('SET_MY_TEAM', team)

          if (!state.selectedTeam) {
            commit('SET_SELECTED_TEAM', {
              teamId: team.teamId,
              name: team.name
            })
          }
        }

        const childTeamRes = await getAllTeamsApi(true, state.myTeam.teamId)
        if (childTeamRes.code === 0 && childTeamRes.result) {
          const childTeamList = getTeamIdList(childTeamRes.result)
          commit('SET_CHILD_TEAM_LIST', childTeamList)
        }

        const allTeamRes = await getAllTeamsApi(false)
        if (allTeamRes.code === 0 && allTeamRes.result) {
          const allTeamList = getTeamIdList(allTeamRes.result)
          commit('SET_ALL_TEAM_LIST', allTeamList)
        }

        let tempSelectedTeam = state.allTeamList.find(item => item.teamId === state.selectedTeam.teamId)
        if (!tempSelectedTeam) {
          tempSelectedTeam = state.myTeam
        }
        commit('SET_SELECTED_TEAM', tempSelectedTeam)
      } else {
        commit('SET_ORGANIZATION', null)
        commit('SET_ALL_TEAM_LIST', null)
        commit('SET_CHILD_TEAM_LIST', null)
        commit('SET_MY_TEAM', null)
        commit('SET_PERMISSION_LIST', null)
        commit('SET_SELECTED_TEAM', null)
      }
    },
    setTeam ({ commit }, value) {
      commit('SET_SELECTED_TEAM', value)
    }
  }
}

const getTeamIdList = teamTree => {
  const teamList = [{
    teamId: teamTree.teamId,
    name: teamTree.name,
    depth: teamTree.depth
  }]
  if (teamTree.children) {
    for (const children of teamTree.children) {
      teamList.push(...getTeamIdList(children))
    }
  }
  return teamList
}

export default organization
